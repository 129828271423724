import { useState } from 'react';
import { Cloud, Copy, Twitter, Loader2, AlertCircle, Layout, Wand2, Sparkles } from 'lucide-react';
import { generatorService } from '../services/generator';
import { useUser } from '../contexts/UserContext';
import { Card, CardContent } from '../components/Card';
import TemplateSelector from '../components/TemplateSelector';
import type { TweetTemplate } from '../data/tweetTemplates';
import HookSelector from '../components/HookSelector';
import { useAnalytics } from '../components/AnalyticsProvider';

const TweetGenerator = () => {
  const { trackEvent, trackGenerationStart, trackGenerationComplete } = useAnalytics();

  const { user } = useUser();
  const [prompt, setPrompt] = useState('');
  const [generatedTweet, setGeneratedTweet] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TweetTemplate | null>(null);
  const [templateVariables, setTemplateVariables] = useState<Record<string, string>>({});

  const isPremiumUser = user?.subscription_tier === 'premium'; // Add your premium check logic
  const [showHooks, setShowHooks] = useState(false);

  const handleTemplateSelect = (template: TweetTemplate) => {
    if (template.isPremium && !isPremiumUser) {
      setError('This is a premium template. Please upgrade to access it.');
      trackEvent('template_access_denied', {
        template_id: template.id,
        user_type: 'free'
      });
      return;
    }

    trackEvent('use_template', {
      template_id: template.id,
      template_name: template.name,
      template_category: template.category,
      is_premium_template: template.isPremium
    });

    setSelectedTemplate(template);
    // Initialize template variables with empty strings
    const variables = template.variables.reduce((acc, variable) => {
      acc[variable] = '';
      return acc;
    }, {} as Record<string, string>);
    setTemplateVariables(variables);
    setShowTemplates(false);
  };

  const handleVariableChange = (variable: string, value: string) => {
    setTemplateVariables(prev => ({
      ...prev,
      [variable]: value
    }));
  };

  const generateFromTemplate = () => {
    if (!selectedTemplate) return;

    let generatedPrompt = selectedTemplate.template;
    Object.entries(templateVariables).forEach(([key, value]) => {
      generatedPrompt = generatedPrompt.replace(`{${key}}`, value);
    });

    setPrompt(generatedPrompt);
  };

  const handleGenerate = async () => {
    if (!prompt) {
      setError('Please enter a prompt first');
      return;
    }

    setIsLoading(true);
    setError('');
    setNotification('');

    try {
      const response = await generatorService.generate({
        type: 'tweet',
        prompt,
        userId: user?.id
      });

      setGeneratedTweet(response.content);
      setHashtags(response.additional_content.hashtags);
      trackGenerationComplete({
        type: 'tweet',
        prompt,
        usedTemplate: !!selectedTemplate,
        isPremiumUser: user?.subscription_tier === 'premium'
      });

      setNotification('Tweet generated successfully! ✨');
      setTimeout(() => setNotification(''), 3000);
    } catch (err: any) {
      if (err.message.includes('sign in')) {
        setError(`You've reached the guest limit. Please sign in to continue generating tweets.`);
      } else {
        setError('Failed to generate tweet. Please try again.');
      }
      trackEvent('generation_error', {
        error_type: err.message.includes('sign in') ? 'guest_limit' : 'generation_failed',
        prompt_length: prompt.length
      });

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
          Generate Powerful Tweets
        </h1>
        <p className="text-gray-600 dark:text-gray-300">
          Transform your ideas into engaging tweets with AI-powered suggestions
        </p>
      </div>


      {/* <div className="mb-6">
        <button
          onClick={() => setShowTemplates(!showTemplates)}
          className="flex items-center justify-center w-full p-3 bg-white dark:bg-gray-800 
            border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 
            dark:hover:bg-gray-700 transition-colors"
        >
          {showTemplates ? (
            <>
              <Layout className="mr-2 h-5 w-5" />
              Hide Templates
            </>
          ) : (
            <>
              <Wand2 className="mr-2 h-5 w-5" />
              Browse Templates
            </>
          )}
        </button>
      </div>
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => {
            setShowHooks(true);
            setShowTemplates(false);
          }}
          className="flex-1 p-3 bg-white dark:bg-gray-800 border rounded-lg
      hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          <Sparkles className="mr-2 h-5 w-5 inline-block" />
          Viral Hooks
        </button>
        <button
          onClick={() => {
            setShowTemplates(true);
            setShowHooks(false);
          }}
          className="flex-1 p-3 bg-white dark:bg-gray-800 border rounded-lg
      hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          <Layout className="mr-2 h-5 w-5 inline-block" />
          Templates
        </button>
      </div> */}

      {showHooks && (
        <Card className="mb-6">
          <CardContent>
            <HookSelector
              onSelect={(hook) => {
                setSelectedTemplate({
                  ...hook,
                  isPremium: false,
                  variables: hook.fields
                });
                setShowHooks(false);
              }}
            />
          </CardContent>
        </Card>
      )}

      {/* Template Selector */}
      {showTemplates && (
        <Card className="mb-6">
          <CardContent>
            <TemplateSelector
              onSelectTemplate={handleTemplateSelect}
              isPremium={isPremiumUser}
            />
          </CardContent>
        </Card>
      )}

      {/* Template Variables Form */}
      {selectedTemplate && !showTemplates && (
        <Card className="mb-6">
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="font-medium">Template: {selectedTemplate.name}</h3>
                <button
                  onClick={() => setSelectedTemplate(null)}
                  className="text-sm text-gray-500 hover:text-gray-700"
                >
                  Clear Template
                </button>
              </div>

              {selectedTemplate.variables.map((variable) => (
                <div key={variable} className="space-y-1">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    {variable.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </label>
                  <input
                    type="text"
                    value={templateVariables[variable]}
                    onChange={(e) => handleVariableChange(variable, e.target.value)}
                    className="w-full p-2 border rounded-lg dark:bg-gray-800 dark:border-gray-700"
                    placeholder={`Enter ${variable.replace(/_/g, ' ')}`}
                  />
                </div>
              ))}

              <button
                onClick={generateFromTemplate}
                className="w-full bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700
                  transition-colors"
              >
                Apply Template
              </button>
            </div>
          </CardContent>
        </Card>
      )}

      {/* Main Tweet Generation Form */}
      <Card className="mb-8">
        <CardContent>
          <div className="relative mb-4">
            <textarea
              className={`w-full p-4 border rounded-lg min-h-[150px] resize-none bg-transparent
                dark:text-white dark:border-gray-700 focus:outline-none focus:ring-2
                ${error ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-500'}`}
              placeholder="Enter your tweet idea..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              maxLength={280}
            />
            <div className="absolute bottom-2 right-2 text-sm text-gray-500">
              {prompt.length}/280
            </div>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200 
              rounded-lg flex items-center">
              <AlertCircle className="h-4 w-4 mr-2" />
              {error}
            </div>
          )}

          {notification && (
            <div className="mb-4 p-3 bg-green-100 dark:bg-green-900/50 text-green-700 
              dark:text-green-200 rounded-lg">
              {notification}
            </div>
          )}

          <button
            onClick={handleGenerate}
            disabled={isLoading || !prompt}
            className="w-full bg-blue-600 text-white p-4 rounded-lg flex items-center 
              justify-center transition-colors disabled:opacity-50 disabled:cursor-not-allowed
              hover:bg-blue-700"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                Generating...
              </>
            ) : (
              <>
                <Cloud className="mr-2 h-5 w-5" />
                Generate Tweet
              </>
            )}
          </button>
        </CardContent>
      </Card>

      {/* Generated Tweet */}
      {generatedTweet && (
        <Card>
          <CardContent>
            <p className="text-gray-900 dark:text-white mb-4">{generatedTweet}</p>
            <div className="flex flex-wrap gap-2 mb-4">
              {hashtags.map((tag) => (
                <span key={tag} className="bg-blue-100 dark:bg-blue-900 text-blue-600 
                  dark:text-blue-300 px-3 py-1 rounded-full text-sm">
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(`${generatedTweet} ${hashtags.join(' ')}`);
                  setNotification('Copied to clipboard!');
                  setTimeout(() => setNotification(''), 2000);
                }}
                className="flex-1 border border-gray-200 dark:border-gray-700 rounded-lg p-2 
                  flex items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <Copy className="mr-2 h-4 w-4" />
                Copy
              </button>
              <button className="flex-1 border border-gray-200 dark:border-gray-700 rounded-lg 
                p-2 flex items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-700">
                <Twitter className="mr-2 h-4 w-4" />
                Post to Twitter
              </button>
            </div>
          </CardContent>
        </Card>
      )}

      {!user && (
        <div className="mt-8 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
          <p className="text-sm text-blue-600 dark:text-blue-300 text-center">
            Sign in to unlock unlimited generations and premium templates
          </p>
        </div>
      )}
    </div>
  );
};

export default TweetGenerator;