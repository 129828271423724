import React from 'react';
import { Activity, Cloud, Hash, User } from 'lucide-react';

const StatsCard = ({ icon: Icon, label, value, percentageChange }) => (
  <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <Icon className="h-5 w-5 text-blue-600 mr-2" />
        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">{label}</h3>
      </div>
      {percentageChange && (
        <span className={`text-sm ${
          percentageChange > 0 ? 'text-green-500' : 'text-red-500'
        }`}>
          {percentageChange > 0 ? '+' : ''}{percentageChange}%
        </span>
      )}
    </div>
    <p className="text-2xl font-bold text-gray-900 dark:text-white">{value}</p>
  </div>
);

const DashboardStats = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <StatsCard 
        icon={Activity}
        label="Total Generations"
        value={stats.total}
        percentageChange={stats.totalChange}
      />
      <StatsCard 
        icon={Cloud}
        label="Tweets"
        value={stats.tweets}
        percentageChange={stats.tweetsChange}
      />
      <StatsCard 
        icon={Hash}
        label="Hashtags"
        value={stats.hashtags}
        percentageChange={stats.hashtagsChange}
      />
      <StatsCard 
        icon={User}
        label="Bios & Handles"
        value={stats.bios + stats.handles}
        percentageChange={stats.biosHandlesChange}
      />
    </div>
  );
};

export default DashboardStats;