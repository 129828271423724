import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initGA, trackEvent, analyticsEvents } from '../services/analytics';
import { useUser } from '../contexts/UserContext';

import { ReactNode } from 'react';

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    initGA();
  }, []);


  console.log('user', user);

  useEffect(() => {
    // Track page views
    trackEvent(analyticsEvents.PAGE_VIEW, {
      page_title: document.title,
      page_path: location.pathname,
      user_type: user ? (user.subscription_tier === 'premium' ? 'premium' : 'free') : 'guest'
    });
  }, [location, user]);

  return <>{children}</>;
};

// Custom hook for analytics
export const useAnalytics = () => {
  const startTime = React.useRef<number>();

  const trackGenerationStart = () => {
    startTime.current = Date.now();
  };

  const trackGenerationComplete = (params: {
    type: 'tweet' | 'hashtags' | 'bio' | 'handle';
    prompt: string;
    usedTemplate?: boolean;
    usedHook?: boolean;
    isPremiumUser?: boolean;
  }) => {
    const generationTime = startTime.current ? Date.now() - startTime.current : undefined;

    trackEvent(`generate_${params.type}`, {
      prompt_length: params.prompt.length,
      used_template: params.usedTemplate,
      used_hook: params.usedHook,
      generation_time: generationTime,
      is_premium_user: params.isPremiumUser
    });
  };

  return {
    trackEvent,
    trackGenerationStart,
    trackGenerationComplete
  };
};