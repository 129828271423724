import { supabase } from '../lib/supabase';

export type GenerationType = 'tweet' | 'hashtag' | 'handle' | 'bio';

export interface GenerateRequest {
  type: GenerationType;
  prompt: string;
  userId?: string;
}

export interface GenerationStats {
  total: number;
  tweets: number;
  hashtags: number;
  handles: number;
  bios: number;
  totalChange: number;
  tweetsChange: number;
  hashtagsChange: number;
  biosHandlesChange: number;
}

export const generatorService = {
  generate: async ({ type, prompt, userId, options = {} }: { type: GenerationType; prompt: string; userId?: string; options?: any }) => {
    try {
      // Check guest usage
      if (!userId) {
        const guestUsage = localStorage.getItem(`guest_${type}_usage`) || '0';
        if (parseInt(guestUsage) >= 1) {
          throw new Error('Please sign in to generate more content');
        }
        localStorage.setItem(`guest_${type}_usage`, '1');
      }

      // Generate content using Claude
      const response = await supabase.functions.invoke('generate', {
        body: {
          type,
          prompt,
          options
        }
      });

      if (userId) {
        await supabase.from('generations').insert({
          user_id: userId,
          type,
          prompt,
          content: response.data.content,
          additional_content: {
            ...response.data.additional_content,
            style: options.style,
            platform: options.platform
          },
          metadata: {
            style: options.style,
            platform: options.platform
          }
        });
      }

      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error('Generation error:', error);
      throw error;
    }
  },
  

  getUserStats: async (userId: string): Promise<GenerationStats> => {
    try {
      // Get current period stats
      const { data: currentData, error: currentError } = await supabase
        .from('generations')
        .select('type, created_at')
        .eq('user_id', userId)
        .gte('created_at', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString()); // Last 30 days

      if (currentError) throw currentError;

      // Get previous period stats for comparison
      const { data: previousData, error: previousError } = await supabase
        .from('generations')
        .select('type, created_at')
        .eq('user_id', userId)
        .gte('created_at', new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString())
        .lt('created_at', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString());

      if (previousError) throw previousError;

      // Calculate current period stats
      const current = {
        total: currentData.length,
        tweets: currentData.filter(g => g.type === 'tweet').length,
        hashtags: currentData.filter(g => g.type === 'hashtag').length,
        handles: currentData.filter(g => g.type === 'handle').length,
        bios: currentData.filter(g => g.type === 'bio').length
      };

      // Calculate previous period stats
      const previous = {
        total: previousData.length,
        tweets: previousData.filter(g => g.type === 'tweet').length,
        hashtags: previousData.filter(g => g.type === 'hashtag').length,
        handles: previousData.filter(g => g.type === 'handle').length,
        bios: previousData.filter(g => g.type === 'bio').length
      };

      // Calculate percentage changes
      const calculateChange = (current: number, previous: number): number => {
        if (previous === 0) return current > 0 ? 100 : 0;
        return Math.round(((current - previous) / previous) * 100);
      };

      return {
        ...current,
        totalChange: calculateChange(current.total, previous.total),
        tweetsChange: calculateChange(current.tweets, previous.tweets),
        hashtagsChange: calculateChange(current.hashtags, previous.hashtags),
        biosHandlesChange: calculateChange(
          current.handles + current.bios,
          previous.handles + previous.bios
        )
      };
    } catch (error) {
      console.error('Error fetching user stats:', error);
      throw error;
    }
  },

  deleteGeneration: async (id: string): Promise<void> => {
    try {
      const { error } = await supabase
        .from('generations')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting generation:', error);
      throw error;
    }
  },

  updateGeneration: async (id: string, updates: Partial<any>): Promise<void> => {
    try {
      const { error } = await supabase
        .from('generations')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating generation:', error);
      throw error;
    }
  },

  exportGenerations: async (
    userId: string,
    filters?: { type?: GenerationType; dateRange?: string }
  ): Promise<string> => {
    try {
      // Build query based on filters
      let query = supabase
        .from('generations')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (filters?.type) {
        query = query.eq('type', filters.type);
      }

      if (filters?.dateRange) {
        const date = new Date();
        switch (filters.dateRange) {
          case 'week':
            date.setDate(date.getDate() - 7);
            break;
          case 'month':
            date.setMonth(date.getMonth() - 1);
            break;
          case 'year':
            date.setFullYear(date.getFullYear() - 1);
            break;
        }
        if (filters.dateRange !== 'all') {
          query = query.gte('created_at', date.toISOString());
        }
      }

      const { data, error } = await query;
      if (error) throw error;

      // Convert to CSV
      const headers = ['Type', 'Prompt', 'Content', 'Created At'];
      const rows = data.map(generation => [
        generation.type,
        generation.prompt,
        generation.content,
        new Date(generation.created_at).toLocaleDateString()
      ]);

      const csvContent = [
        headers.join(','),
        ...rows.map(row => 
          row.map(cell => 
            `"${(cell || '').toString().replace(/"/g, '""')}"`
          ).join(',')
        )
      ].join('\n');

      return csvContent;
    } catch (error) {
      console.error('Error exporting generations:', error);
      throw error;
    }
  },

  getUserGenerations: async (params: {
    userId: string;
    type?: GenerationType;
    dateRange?: string;
    page?: number;
    perPage?: number;
  }): Promise<{ generations: any[]; hasMore: boolean }> => {
    try {
      const { userId, type, dateRange, page = 1, perPage = 10 } = params;
      let query = supabase
        .from('generations')
        .select('*', { count: 'exact' })
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (type) {
        query = query.eq('type', type);
      }

      if (dateRange && dateRange !== 'all') {
        const date = new Date();
        switch (dateRange) {
          case 'week':
            date.setDate(date.getDate() - 7);
            break;
          case 'month':
            date.setMonth(date.getMonth() - 1);
            break;
          case 'year':
            date.setFullYear(date.getFullYear() - 1);
            break;
        }
        query = query.gte('created_at', date.toISOString());
      }

      // Add pagination
      const from = (page - 1) * perPage;
      const to = from + perPage - 1;
      query = query.range(from, to);

      const { data, error, count } = await query;
      if (error) throw error;

      return {
        generations: data,
        hasMore: count ? from + perPage < count : false
      };
    } catch (error) {
      console.error('Error fetching user generations:', error);
      throw error;
    }
  }

};

// Helper functions
function isWithinGuestLimit(type: GenerationType): boolean {
  const usage = localStorage.getItem(`guest_${type}_usage`);
  return !usage || parseInt(usage) < 1;
}

function incrementGuestUsage(type: GenerationType): void {
  const current = localStorage.getItem(`guest_${type}_usage`) || '0';
  localStorage.setItem(`guest_${type}_usage`, (parseInt(current) + 1).toString());
}

async function saveGeneration(generation: any) {
  const { error } = await supabase
    .from('generations')
    .insert([generation]);
  
  if (error) throw error;
}