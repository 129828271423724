import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from './components/ThemeProvider'
import Layout from './components/Layout'
import Dashboard from './pages/Dashboard'
import TweetGenerator from './pages/TweetGenerator'
import HashtagGenerator from './pages/HashtagGenerator'
import HandleGenerator from './pages/HandleGenerator'
import BioGenerator from './pages/BioGenerator'
import AuthCallback from './pages/AuthCallback'
import ProtectedRoute from './components/ProtectedRoute'
import { UserProvider } from './contexts/UserContext'
import AuthPage from './pages/AuthPage'


const App = () => {
  return (
    <ThemeProvider>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<TweetGenerator />} />
              <Route path="hashtags" element={<HashtagGenerator />} />
              <Route path="handle" element={<HandleGenerator />} />
              <Route path="bio" element={<BioGenerator />} />
              <Route path="auth" element={<AuthPage />} />
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="auth/callback" element={<AuthCallback />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    </ThemeProvider>
  )
}

export default App