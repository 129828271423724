import React, { useState } from 'react';
import {
  UserCircle, Copy, Loader2, AlertCircle, ThumbsUp, Save,
  Briefcase, Sparkles, Smile
} from 'lucide-react';
import { generatorService } from '../services/generator';
import { useUser } from '../contexts/UserContext';
import { Card, CardContent } from '../components/Card';

const BioGenerator = () => {
  const { user } = useUser();
  const [prompt, setPrompt] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('linkedin');
  const [bios, setBios] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');
  const [selectedBio, setSelectedBio] = useState<number | null>(null);
  const [characterCount, setCharacterCount] = useState(0);

  const platforms = [
    {
      id: 'linkedin',
      name: 'LinkedIn',
      maxLength: 220,
      icon: 'linkedin',
      description: 'Professional and achievement-focused'
    },
    {
      id: 'twitter',
      name: 'Twitter/X',
      maxLength: 160,
      icon: 'twitter',
      description: 'Concise and engaging'
    },
    {
      id: 'instagram',
      name: 'Instagram',
      maxLength: 150,
      icon: 'instagram',
      description: 'Creative and personality-driven'
    },
    {
      id: 'tiktok',
      name: 'TikTok',
      maxLength: 80,
      icon: 'video',
      description: 'Fun and trend-focused'
    }
  ];

  const [selectedStyle, setSelectedStyle] = useState('professional');

  const writingStyles = [
    {
      id: 'professional',
      name: 'Professional',
      icon: Briefcase,
      description: 'Polished and business-focused',
      example: 'Strategic leader with 10+ years experience...',
      tone: 'Formal and achievement-oriented'
    },
    {
      id: 'creative',
      name: 'Creative',
      icon: Sparkles,
      description: 'Unique and artistic',
      example: '✨ Turning ideas into digital magic...',
      tone: 'Imaginative and expressive'
    },
    {
      id: 'casual',
      name: 'Casual',
      icon: Smile,
      description: 'Friendly and approachable',
      example: 'Coffee lover, dog parent, tech enthusiast...',
      tone: 'Conversational and relatable'
    }
  ];


  const handlePromptChange = (e: { target: { value: any; }; }) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
    setCharacterCount(newPrompt.length);
    setError('');
  };

  const handleGenerate = async () => {
    if (!prompt) {
      setError('Please describe yourself or your brand first');
      return;
    }

    setIsLoading(true);
    setError('');
    setNotification('');

    try {
      const response = await generatorService.generate({
        type: 'bio',
        prompt: `${prompt} (Platform: ${selectedPlatform}, Style: ${selectedStyle})`,
        userId: user?.id,
        options: {
          style: selectedStyle,
          platform: selectedPlatform
        }
      });

      setBios(response.additional_content.bios);
      setNotification('Bios generated successfully! ✨');
      setTimeout(() => setNotification(''), 3000);
    } catch (err) {
      if (err instanceof Error && err.message.includes('sign in')) {
        setError(`You've reached the guest limit. Please sign in to generate more bios.`);
      } else {
        setError('Failed to generate bios. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setNotification('Copied to clipboard! 📋');
      setTimeout(() => setNotification(''), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  const getCharacterLimit = () => {
    const platform = platforms.find(p => p.id === selectedPlatform);
    return platform?.maxLength || 150;
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
          Professional Bio Generator
        </h1>
        <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
          Create engaging bios for your social media profiles. Tailored for different platforms
          and optimized for maximum impact.
        </p>
      </div>

      <Card className="mb-8">
        <CardContent>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Choose Writing Style
            </label>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {writingStyles.map((style) => (
                <button
                  key={style.id}
                  onClick={() => setSelectedStyle(style.id)}
                  className={`p-4 rounded-lg text-left transition-all ${selectedStyle === style.id
                    ? 'bg-blue-50 dark:bg-blue-900/50 border-2 border-blue-500'
                    : 'bg-gray-50 dark:bg-gray-800 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700'
                    }`}
                >
                  <div className="flex items-center mb-2">
                    <style.icon className="h-5 w-5 mr-2 text-blue-600" />
                    <span className="font-medium">{style.name}</span>
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                    {style.description}
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 italic">
                    "{style.example}"
                  </div>
                </button>
              ))}
            </div>
          </div>
          {/* Platform Selection */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Choose Platform
            </label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              {platforms.map((platform) => (
                <button
                  key={platform.id}
                  onClick={() => setSelectedPlatform(platform.id)}
                  className={`p-4 rounded-lg text-left transition-all ${selectedPlatform === platform.id
                    ? 'bg-blue-50 dark:bg-blue-900/50 border-2 border-blue-500'
                    : 'bg-gray-50 dark:bg-gray-800 border-2 border-transparent'
                    }`}
                >
                  <div className="font-medium mb-1">{platform.name}</div>
                  <div className="text-xs text-gray-500 dark:text-gray-400">
                    {platform.description}
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <div className="text-sm text-gray-600 dark:text-gray-300 mb-2">
              Selected Style: <span className="font-medium">{writingStyles.find(s => s.id === selectedStyle)?.name}</span>
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Tone: {writingStyles.find(s => s.id === selectedStyle)?.tone}
            </div>
          </div>

          {/* Input Area */}
          <div className="relative mb-4">
            <textarea
              className={`w-full p-4 border rounded-lg min-h-[120px] resize-none bg-transparent
                dark:text-white dark:border-gray-700 focus:outline-none focus:ring-2
                ${error ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-500'}`}
              placeholder={`Tell us about yourself or your brand...
• What makes you unique?
• What are your key achievements?
• What's your expertise?
• What's your personality like?

Your bio will be crafted in a ${writingStyles.find(s => s.id === selectedStyle)?.name.toLowerCase()} style.`}
              value={prompt}
              onChange={handlePromptChange}
              maxLength={500}
              disabled={isLoading}
            />
            <div className="absolute bottom-2 right-2 text-sm text-gray-500">
              {characterCount}/500
            </div>
          </div>


          {error && (
            <div className="mb-4 p-3 bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200 rounded-lg flex items-center">
              <AlertCircle className="h-4 w-4 mr-2" />
              {error}
            </div>
          )}

          {notification && (
            <div className="mb-4 p-3 bg-green-100 dark:bg-green-900/50 text-green-700 dark:text-green-200 rounded-lg flex items-center">
              <ThumbsUp className="h-4 w-4 mr-2" />
              {notification}
            </div>
          )}

          <button
            className={`w-full bg-blue-600 text-white p-4 rounded-lg flex items-center justify-center
              transition-colors duration-200 ${isLoading || !prompt
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-blue-700'
              }`}
            onClick={handleGenerate}
            disabled={isLoading || !prompt}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                Creating your bios...
              </>
            ) : (
              <>
                <UserCircle className="mr-2 h-5 w-5" />
                Generate Bio
              </>
            )}
          </button>
        </CardContent>
      </Card>

      {/* Generated Bios */}
      {bios.length > 0 && (
        <div className="space-y-4">
          {bios.map((bio, index) => (
            <Card key={index} className={`transition-all duration-200 ${selectedBio === index ? 'ring-2 ring-blue-500' : ''
              }`}>
              <CardContent>
                <div className="flex items-start justify-between mb-3">
                  <div>
                    <span className="inline-block px-2 py-1 text-sm rounded-full bg-blue-100 
                      dark:bg-blue-900 text-blue-600 dark:text-blue-300 mb-2">
                      Version {index + 1}
                    </span>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {bio.length} / {getCharacterLimit()} characters
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => copyToClipboard(bio)}
                      className="p-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300
                        rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                    >
                      <Copy className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => setSelectedBio(index)}
                      className="p-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300
                        rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                    >
                      <Save className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <p className="text-gray-900 dark:text-white whitespace-pre-wrap">
                  {bio.toString()}
                </p>
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {!user && bios.length > 0 && (
        <div className="mt-8 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg text-center">
          <p className="text-sm text-blue-600 dark:text-blue-300">
            Sign in to save your favorite bios and generate unlimited versions
          </p>
        </div>
      )}
    </div>
  );
};

export default BioGenerator;