export interface TweetTemplate {
  id: string;
  name: string;
  description: string;
  category: 'marketing' | 'personal' | 'business' | 'engagement' | 'announcement' | 'premium';
  template: string;
  example: string;
  variables: string[];
  isPremium: boolean;
}

export const tweetTemplates: TweetTemplate[] = [
  // Free Templates
  {
    id: 'product-launch',
    name: 'Product Launch',
    description: 'Announce a new product or feature',
    category: 'announcement',
    template: '🚀 Excited to announce {product_name}!\n\n{key_feature}\n\n{call_to_action}\n\nLearn more: {link}',
    example: '🚀 Excited to announce StormTweets!\n\nAI-powered tweet generation for maximum engagement\n\nTry it now for free!\n\nLearn more: stormtweets.com',
    variables: ['product_name', 'key_feature', 'call_to_action', 'link'],
    isPremium: false
  },
  {
    id: 'tip-share',
    name: 'Quick Tip',
    description: 'Share a helpful tip or trick',
    category: 'engagement',
    template: '💡 Quick Tip:\n\n{tip}\n\n👉 {additional_info}\n\n#Tips #{category}',
    example: '💡 Quick Tip:\n\nUse AI to generate engaging tweets in seconds\n\n👉 Save hours on content creation\n\n#ProductivityTips #ContentCreation',
    variables: ['tip', 'additional_info', 'category'],
    isPremium: false
  },
  // Premium Templates
  {
    id: 'viral-hook',
    name: 'Viral Hook Format',
    description: 'Engaging hook format proven to drive virality',
    category: 'premium',
    template: `I went from {before} to {after} in {timeframe}.\n\nHere's how:\n\n{steps}\n\nFollow for more {topic} tips!`,
    example: `I went from 100 to 10k followers in 30 days.\n\nHere's how:\n\n1. Post daily\n2. Use AI tools\n3. Engage with others\n\nFollow for more growth tips!`,
    variables: ['before', 'after', 'timeframe', 'steps', 'topic'],
    isPremium: true
  },
  {
    id: 'thread-starter',
    name: 'Thread Starter',
    description: 'Start a compelling thread that drives engagement',
    category: 'premium',
    template: '{attention_grabber}\n\nA thread 🧵 on {topic}\n\n{hook}\n\n(1/X)',
    example: `🤯 My content went viral overnight\n\nA thread 🧵 on AI content creation\n\nI'll show you exactly how I did it.\n\n(1/X)`,
    variables: ['attention_grabber', 'topic', 'hook'],
    isPremium: true
  },

];