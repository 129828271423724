import React, { useState } from 'react';
import { tweetHooks, categories } from '../data/tweetHooks';
import { Card, CardContent } from './Card';

interface HookSelectorProps {
  onSelect: (hook: any) => void;
}

const HookSelector: React.FC<HookSelectorProps> = ({ onSelect }) => {
  const [selectedCategory, setSelectedCategory] = useState<keyof typeof tweetHooks>('transformation');

  return (
    <div className="space-y-6">
      {/* Category Selection */}
      <div className="flex overflow-x-auto space-x-2 pb-2">
        {categories.map(category => (
          <button
            key={category.id}
            //@ts-ignore
            onClick={() => setSelectedCategory(category.id)}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 whitespace-nowrap
              ${selectedCategory === category.id
                ? 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300'
                : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-300'
              }`}
          >
            <span>{category.icon}</span>
            <span>{category.name}</span>
          </button>
        ))}
      </div>

      {/* Hooks Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {tweetHooks[selectedCategory].map(hook => (
          <Card
            key={hook.id}
            className="cursor-pointer hover:shadow-md transition-shadow"
            onClick={() => onSelect(hook)}
          >
            <CardContent>
              <h3 className="font-medium mb-2">{hook.name}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
                {hook.template}
              </p>
              <div className="text-xs text-gray-500 dark:text-gray-400">
                Example:
                <div className="mt-1 text-gray-700 dark:text-gray-200">
                  {hook.example}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HookSelector;