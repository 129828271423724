// Initialize GA4
export const initGA = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-204VEKVPRC`;
  script.async = true;
  document.head.appendChild(script);
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  function gtag(){dataLayer.push(arguments);}
  //@ts-ignore
  gtag('js', new Date());
  //@ts-ignore
  gtag('config', 'G-204VEKVPRC');
};

// Event tracking utility
export const trackEvent = (
  eventName: string,
  eventParams: Record<string, any> = {}
) => {

  //@ts-ignore
  if (window.gtag) {
    //@ts-ignore
    window.gtag('event', eventName, eventParams);
  }
};

// Common events
export const analyticsEvents = {
  // Generation events
  GENERATE_TWEET: 'generate_tweet',
  GENERATE_HASHTAGS: 'generate_hashtags',
  GENERATE_BIO: 'generate_bio',
  GENERATE_HANDLE: 'generate_handle',

  // Template events
  USE_TEMPLATE: 'use_template',
  USE_HOOK: 'use_hook',

  // User events
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  UPGRADE_ACCOUNT: 'upgrade_account',

  // Interaction events
  COPY_CONTENT: 'copy_content',
  SAVE_GENERATION: 'save_generation',
  SHARE_CONTENT: 'share_content',

  // Navigation events
  PAGE_VIEW: 'page_view',
  TOGGLE_THEME: 'toggle_theme'
};