import React, { useState } from 'react';
import { Cloud, Hash, AtSign, User, Trash2, Copy, Calendar, Share2, Star } from 'lucide-react';

interface Generation {
  id: string;
  type: 'tweet' | 'hashtag' | 'handle' | 'bio';
  is_favorite: boolean;
  prompt: string;
  content: string;
  created_at: string;
  additional_content?: {
    hashtags?: string[];
  };
}

interface GenerationItemProps {
  generation: Generation;
  onDelete: (id: string) => void;
  onCopy: (generation: Generation) => Promise<void>;
  onFavorite: (id: string, isFavorite: boolean) => void;
}

const GenerationItem: React.FC<GenerationItemProps> = ({
  generation,
  onDelete,
  onCopy,
  onFavorite
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isFavorite, setIsFavorite] = useState(generation.is_favorite);

  const getIcon = (type: string) => {
    switch (type) {
      case 'tweet': return Cloud;
      case 'hashtag': return Hash;
      case 'handle': return AtSign;
      case 'bio': return User;
      default: return Cloud;
    }
  };

  const Icon = getIcon(generation.type);

  const handleCopy = async () => {
    await onCopy(generation);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    onFavorite(generation.id, !isFavorite);
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center">
          <Icon className="h-5 w-5 text-blue-600 mr-2" />
          <span className="text-sm font-medium text-gray-900 dark:text-white capitalize">
            {generation.type}
          </span>
        </div>

        <div className="flex items-center space-x-3">
          <button
            onClick={handleFavorite}
            className={`${isFavorite
              ? 'text-yellow-500'
              : 'text-gray-400 hover:text-yellow-500'
              } transition-colors duration-200`}
          >
            <Star className="h-4 w-4" fill={isFavorite ? 'currentColor' : 'none'} />
          </button>

          <button
            onClick={handleCopy}
            className={`${isCopied
              ? 'text-green-500'
              : 'text-gray-400 hover:text-gray-600 dark:hover:text-gray-200'
              } transition-colors duration-200`}
          >
            <Copy className="h-4 w-4" />
          </button>

          <button
            onClick={() => onDelete(generation.id)}
            className="text-gray-400 hover:text-red-600 transition-colors duration-200"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>

      <p className="text-gray-600 dark:text-gray-300 text-sm mb-2">
        Prompt: {generation.prompt}
      </p>

      <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg mb-4">
        <p className="text-gray-900 dark:text-white">
          {generation.content}
        </p>

        {generation.type === 'tweet' && generation.additional_content?.hashtags && (
          <div className="flex flex-wrap gap-2 mt-3">
            {generation.additional_content.hashtags.map((tag) => (
              <span
                key={tag}
                className="bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300 
                  px-2 py-1 rounded-full text-sm"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400">
        <div className="flex items-center">
          <Calendar className="h-4 w-4 mr-1" />
          {formatDate(generation.created_at)}
        </div>

        <button
          className="flex items-center hover:text-blue-600 transition-colors duration-200"
          onClick={() => {/* Implement sharing functionality */ }}
        >
          <Share2 className="h-4 w-4 mr-1" />
          Share
        </button>
      </div>
    </div>
  );
};

export default GenerationItem;