import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle, Download, Filter } from 'lucide-react';
import { useUser } from '../contexts/UserContext';
import { GenerationType, generatorService } from '../services/generator';
import DashboardStats from '../components/dashboard/DashboardStats';
import GenerationsFilter from '../components/dashboard/GenerationFilter';
import GenerationItem from '../components/dashboard/GenerationItem';
import { Card } from '../components/Card';

const Dashboard = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<GenerationType | 'all'>('all');
  const [dateRange, setDateRange] = useState('all');
  interface Generation {
    id: string;
    content: string;
    type: 'tweet' | 'hashtag' | 'handle' | 'bio';
    is_favorite: boolean;
    prompt: string;
    created_at: string;
    additional_content?: {
      hashtags: string[];
    };
  }

  const [generations, setGenerations] = useState<Generation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [stats, setStats] = useState({
    total: 0,
    tweets: 0,
    hashtags: 0,
    handles: 0,
    bios: 0,
    totalChange: 0,
    tweetsChange: 0,
    hashtagsChange: 0,
    biosHandlesChange: 0
  });
  const [showExportModal, setShowExportModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const perPage = 10;

  useEffect(() => {
    loadGenerations(true);
  }, [activeTab, dateRange]);

  const loadGenerations = async (reset = false) => {
    if (reset) {
      setPage(1);
      setGenerations([]);
    }

    setIsLoading(true);
    setError('');

    try {
      const data = await generatorService.getUserGenerations({
        userId: user.id,
        type: activeTab === 'all' ? undefined : activeTab,
        dateRange,
        page,
        perPage
      });

      //@ts-ignore
      setGenerations(prev => reset ? data.generations : [...prev, ...data.generations]);
      setHasMore(data.hasMore);
      //@ts-ignore
      if (reset) calculateStats(data.generations);
    } catch (err) {
      setError('Failed to load your generations');
      console.error('Error loading generations:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prev => prev + 1);
    loadGenerations(false);
  };

  const calculateStats = async () => {
    try {
      const statsData = await generatorService.getUserStats(user.id);
      setStats(statsData);
    } catch (err) {
      console.error('Error loading stats:', err);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await generatorService.deleteGeneration(id);
      setGenerations(prev => prev.filter(gen => gen.id !== id));
      await calculateStats();
    } catch (err) {
      console.error('Error deleting generation:', err);
    }
  };

  const handleCopy = async (generation: {
    id: string; content: any; type: string; additional_content: { hashtags: any[]; };
  }, isFavorite: boolean) => {
    try {
      let textToCopy = generation.content;
      if (generation.type === 'tweet' && generation.additional_content?.hashtags) {
        textToCopy += ' ' + generation.additional_content.hashtags.join(' ');
      }
      await navigator.clipboard.writeText(textToCopy);
      setGenerations(prev =>
        prev.map((gen: Generation) =>
          gen.id === generation.id ? { ...gen, isFavorite } : gen
        )
      );
    } catch (err) {
      console.error('Error copying generation:', err);
    }
  };

  const handleFavorite = async (id: string, isFavorite: any) => {
    try {
      await generatorService.updateGeneration(id, { is_favorite: isFavorite });
      setGenerations(prev =>
        prev.map(gen =>
          gen.id === id ? { ...gen, is_favorite: isFavorite } : gen
        )
      );
    } catch (err) {
      console.error('Error updating favorite status:', err);
    }
  };

  const handleExport = async () => {
    try {
      const data = await generatorService.exportGenerations(user.id, {
        type: activeTab === 'all' ? undefined : activeTab,
        dateRange
      });

      // Create and download CSV file
      const csvContent = "data:text/csv;charset=utf-8," + data;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "generations.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error exporting generations:', err);
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowExportModal(true)}
            className="flex items-center px-4 py-2 text-sm bg-white dark:bg-gray-800 
              border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 
              dark:hover:bg-gray-700 transition-colors duration-200"
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
          <button
            onClick={() => navigate('/')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700
              transition-colors duration-200"
          >
            New Generation
          </button>
        </div>
      </div>

      <DashboardStats stats={stats} />

      <Card className="mt-8">
        <GenerationsFilter
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Card>

      <div className="mt-8 space-y-4">
        {isLoading && page === 1 ? (
          <div className="flex items-center justify-center py-12">
            <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          </div>
        ) : error ? (
          <div className="bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200 p-4 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        ) : generations.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 dark:text-gray-400 mb-4">
              No generations found for this category
            </p>
            <button
              onClick={() => navigate(activeTab === 'all' ? '/' : `/${activeTab}`)}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
            >
              Create your first {activeTab === 'all' ? 'generation' : activeTab}
            </button>
          </div>
        ) : (
          <>
            {generations.map((generation) => (
              <GenerationItem
                key={generation.id}
                generation={generation}
                onDelete={handleDelete}
                //@ts-ignore
                onCopy={handleCopy}
                onFavorite={handleFavorite}
              />
            ))}

            {hasMore && (
              <div className="text-center pt-4">
                <button
                  onClick={loadMore}
                  disabled={isLoading}
                  className="px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200
                    dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700
                    transition-colors duration-200 disabled:opacity-50"
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin mx-auto" />
                  ) : (
                    'Load More'
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* Export Modal */}
      {showExportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <Card className="w-full max-w-md bg-white dark:bg-gray-800 p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Export Generations</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Download your generations as a CSV file. This will include all generations
              matching your current filters.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowExportModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleExport();
                  setShowExportModal(false);
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Download CSV
              </button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Dashboard;