import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { Loader2 } from 'lucide-react';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { checkSession } = useUser();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get('access_token');
      const type = params.get('type');

      if (accessToken) {
        await checkSession();
        navigate('/dashboard');
      } else if (type === 'recovery') {
        navigate('/auth/reset-password');
      } else {
        navigate('/');
      }
    };

    handleCallback();
  }, [navigate, checkSession]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4" />
        <p className="text-gray-600 dark:text-gray-300">Processing authentication...</p>
      </div>
    </div>
  );
};

export default AuthCallback;