import React, { useState } from 'react';
import { Search, Lock, Star, Filter } from 'lucide-react';
import { tweetTemplates, TweetTemplate } from '../data/tweetTemplates';
import { Card, CardContent } from './Card';

interface TemplateSelectorProps {
  onSelectTemplate: (template: TweetTemplate) => void;
  isPremium: boolean;
}

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  onSelectTemplate,
  isPremium
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'personal', name: 'Personal' },
    { id: 'business', name: 'Business' },
    { id: 'engagement', name: 'Engagement' },
    { id: 'announcement', name: 'Announcements' },
    { id: 'premium', name: 'Premium' },
  ];

  const filteredTemplates = tweetTemplates.filter(template => {
    const matchesSearch = template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || template.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="space-y-4">
      {/* Search and Filter */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          <input
            type="text"
            placeholder="Search templates..."
            className="pl-10 w-full p-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-2">
          <Filter className="text-gray-400 h-4 w-4" />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="p-2 border rounded-lg"
          >
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Templates Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredTemplates.map(template => (
          <Card
            key={template.id}
            className={`cursor-pointer transition-all hover:shadow-md ${template.isPremium && !isPremium ? 'opacity-75' : ''
              }`}
            onClick={() => {
              if (!template.isPremium || isPremium) {
                onSelectTemplate(template);
              }
            }}
          >
            <CardContent>
              <div className="flex items-start justify-between mb-2">
                <div>
                  <h3 className="font-medium flex items-center gap-2">
                    {template.name}
                    {template.isPremium && (
                      <Star className="h-4 w-4 text-yellow-500 fill-yellow-500" />
                    )}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {template.description}
                  </p>
                </div>
                {template.isPremium && !isPremium && (
                  <Lock className="h-4 w-4 text-gray-400" />
                )}
              </div>

              <div className="mt-4">
                <div className="text-xs text-gray-500 mb-1">Example:</div>
                <div className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-lg">
                  {template.example}
                </div>
              </div>

              {template.isPremium && !isPremium && (
                <div className="mt-4 text-center py-2 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
                  <span className="text-sm text-blue-600 dark:text-blue-300">
                    Upgrade to unlock this template
                  </span>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default TemplateSelector;