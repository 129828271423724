import React, { useState } from 'react';
import { AtSign, Copy, Loader2 } from 'lucide-react';
import { generatorService } from '../services/generator';
import { useUser } from '../contexts/UserContext';

const HandleGenerator = () => {
  const { user } = useUser();
  const [prompt, setPrompt] = useState('');
  const [handles, setHandles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    if (!prompt) return;
    setIsLoading(true);

    try {
      const response = await generatorService.generate({
        prompt,
        type: 'handle',
        userId: user?.id
      });

      setHandles(response.additional_content.handles);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Generate Username Ideas</h1>
        <p className="text-gray-600 dark:text-gray-300">Create unique and memorable usernames for your social media accounts</p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 mb-8">
        <div className="mb-4">
          <textarea
            className="w-full p-3 border dark:border-gray-700 rounded-lg min-h-[100px] bg-transparent dark:text-white"
            placeholder="Tell us about yourself, your brand, or what you're looking for in a username..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <button
          className={`w-full bg-blue-600 text-white p-3 rounded-lg flex items-center justify-center ${isLoading || !prompt ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
            }`}
          onClick={handleGenerate}
          disabled={isLoading || !prompt}
        >
          {isLoading ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <AtSign className="mr-2 h-4 w-4" />
          )}
          {isLoading ? 'Generating...' : 'Generate Usernames'}
        </button>
      </div>

      {handles.length > 0 && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
          <div className="space-y-4">
            {handles.map((handle, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 border dark:border-gray-700 rounded-lg"
              >
                <span className="text-gray-900 dark:text-white font-medium">@{handle}</span>
                <button
                  onClick={() => copyToClipboard(handle)}
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-400"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HandleGenerator;