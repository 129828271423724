import React from 'react';
import { Calendar, Filter } from 'lucide-react';
import { GenerationType } from '../../services/generator';

// interface GenerationsFilterProps {
//   activeTab: string;
//   setActiveTab: (tab: string) => void;
//   dateRange: string;
//   setDateRange: (range: string) => void;
// }

interface GenerationsFilterProps {
  activeTab: GenerationType | 'all';
  setActiveTab: React.Dispatch<React.SetStateAction<GenerationType | 'all'>>;
  dateRange: string;
  setDateRange: React.Dispatch<React.SetStateAction<string>>;
}

const GenerationsFilter: React.FC<GenerationsFilterProps> = ({
  activeTab,
  setActiveTab,
  dateRange,
  setDateRange
}) => {
  const tabs = [
    { id: 'all', label: 'All' },
    { id: 'tweet', label: 'Tweets' },
    { id: 'hashtag', label: 'Hashtags' },
    { id: 'handle', label: 'Handles' },
    { id: 'bio', label: 'Bios' }
  ];

  const dateRanges = [
    { id: 'all', label: 'All time' },
    { id: 'week', label: 'Last 7 days' },
    { id: 'month', label: 'Last 30 days' },
    { id: 'year', label: 'Last year' }
  ];

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div className="flex flex-wrap gap-2">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id as GenerationType | 'all')}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                ${activeTab === tab.id
                  ? 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300'
                  : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
                }`}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div className="flex items-center gap-2">
          <Calendar className="h-4 w-4 text-gray-500" />
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="bg-transparent border-none text-sm text-gray-600 dark:text-gray-300 focus:ring-0"
          >
            {dateRanges.map((range) => (
              <option key={range.id} value={range.id}>
                {range.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default GenerationsFilter;