export const tweetHooks = {
  transformation: [
    {
      id: 'achievement-blocker',
      name: 'Achievement Blocker',
      template: "You can't achieve {desired_outcome} if you're still {bad_habit}",
      example: "You can't achieve financial freedom if you're still spending without a budget",
      fields: ['desired_outcome', 'bad_habit']
    },
    {
      id: 'life-change',
      name: 'Life Change Perspective',
      template: 'How different would life look if you actually {desired_outcome}?',
      example: 'How different would life look if you actually started your own business?',
      fields: ['desired_outcome']
    }
  ],
  educational: [
    {
      id: 'quick-tutorial',
      name: 'Quick Tutorial Hook',
      template: 'Wanna learn how to {action}? Watch this 👇',
      example: 'Wanna learn how to create viral content? Watch this 👇',
      fields: ['action']
    },
    {
      id: 'top-five',
      name: 'Top 5 List',
      template: "Here's 5 ways to {action}!\n\n1. {tip_1}\n2. {tip_2}\n3. {tip_3}\n4. {tip_4}\n5. {tip_5} (my personal favorite!)",
      example: "Here's 5 ways to grow your Twitter following!\n\n1. Post daily\n2. Engage with others\n3. Use AI tools\n4. Share your journey\n5. Be authentic (my personal favorite!)",
      fields: ['action', 'tip_1', 'tip_2', 'tip_3', 'tip_4', 'tip_5']
    }
  ],
  engagement: [
    {
      id: 'mistake-share',
      name: 'Mistake Confession',
      template: `I made a big mistake!\n\n{mistake}\n\nHere's what I learned:`,
      example: `I made a big mistake!\n\nI tried to grow my audience without engaging with others\n\nHere's what I learned:`,
      fields: ['mistake']
    },
    {
      id: 'stop-scroll',
      name: 'Pattern Interrupt',
      template: `Stop scrolling {audience}!\n\nLet's fix {problem} now 👇`,
      example: `Stop scrolling creators!\n\nLet's fix your content strategy now 👇`,
      fields: ['audience', 'problem']
    }
  ],
  solution: [
    {
      id: 'quick-solution',
      name: 'Quick Solution',
      template: `The quickest way to solve {problem}:\n\n{solution}`,
      example: `The quickest way to solve writer's block:\n\nUse AI-powered writing assistants`,
      fields: ['problem', 'solution']
    },
    {
      id: 'secret-reveal',
      name: 'Secret Formula',
      template: 'This is the Secret To Getting {result}:\n\n{secret}',
      example: 'This is the Secret To Getting More Engagement:\n\nPost when your audience is most active',
      fields: ['result', 'secret']
    }
  ]
};

export const categories = [
  {
    id: 'transformation',
    name: 'Transformation',
    description: 'Before/After scenarios and achievement posts',
    icon: '🚀'
  },
  {
    id: 'educational',
    name: 'Educational',
    description: 'Teaching and sharing knowledge',
    icon: '📚'
  },
  {
    id: 'engagement',
    name: 'Engagement',
    description: 'Hooks to grab attention',
    icon: '🎯'
  },
  {
    id: 'solution',
    name: 'Solution',
    description: 'Problem-solving posts',
    icon: '💡'
  }
];