import React, { useState } from 'react';
import { Hash, Copy, Loader2, AlertCircle, CheckCircle2 } from 'lucide-react';
import { generatorService } from '../services/generator';
import { useUser } from '../contexts/UserContext';

const HashtagGenerator = () => {
  const { user } = useUser();
  const [prompt, setPrompt] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [copySuccess, setCopySuccess] = useState('');

  const handleGenerate = async () => {
    if (!prompt) {
      setError('Please enter a description first');
      return;
    }

    setIsLoading(true);
    setError('');
    setCopySuccess('');
    setSelectedTags([]);

    try {
      const response = await generatorService.generate({
        type: 'hashtag',
        prompt,
        userId: user?.id
      });

      setHashtags(response.additional_content.hashtags);
      setSelectedTags(response.additional_content.hashtags);
    } catch (err) {
      if (err instanceof Error && err.message.includes('sign in')) {
        setError(`You've reached the guest limit.Please sign in to continue generating hashtags.`);
      } else {
        setError('Failed to generate hashtags. Please try again.');
      }
      console.error('Generation error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTag = (tag: string) => {
    setSelectedTags(prev =>
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const copyToClipboard = async () => {
    if (selectedTags.length === 0) {
      setError('Please select at least one hashtag');
      return;
    }

    try {
      await navigator.clipboard.writeText(selectedTags.join(' '));
      setCopySuccess('Hashtags copied to clipboard!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setError('Failed to copy hashtags');
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
          Generate Perfect Hashtags
        </h1>
        <p className="text-gray-600 dark:text-gray-300">
          Create relevant hashtags to increase your content's reach
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 mb-8">
        <div className="mb-4">
          <textarea
            className={`w-full p-3 border rounded-lg min-h-[100px] resize-none bg-transparent
              dark:text-white dark:border-gray-700 focus:outline-none focus:ring-2
              ${error ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-500'}`}
            placeholder="Describe your content or topic..."
            value={prompt}
            onChange={(e) => {
              setPrompt(e.target.value);
              setError('');
            }}
            disabled={isLoading}
          />
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200 rounded-lg flex items-center">
            <AlertCircle className="h-4 w-4 mr-2" />
            {error}
          </div>
        )}

        {copySuccess && (
          <div className="mb-4 p-3 bg-green-100 dark:bg-green-900/50 text-green-700 dark:text-green-200 rounded-lg flex items-center">
            <CheckCircle2 className="h-4 w-4 mr-2" />
            {copySuccess}
          </div>
        )}

        <button
          className={`w-full bg-blue-600 text-white p-3 rounded-lg flex items-center justify-center
            transition-colors duration-200 ${isLoading || !prompt
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-blue-700'
            }`}
          onClick={handleGenerate}
          disabled={isLoading || !prompt}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-5 w-5 animate-spin" />
              Generating hashtags...
            </>
          ) : (
            <>
              <Hash className="mr-2 h-5 w-5" />
              Generate Hashtags
            </>
          )}
        </button>
      </div>

      {hashtags.length > 0 && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 space-y-4">
          <div className="flex flex-wrap gap-2">
            {hashtags.map((tag) => (
              <button
                key={tag}
                onClick={() => toggleTag(tag)}
                className={`px-3 py-1 rounded-full text-sm transition-colors duration-200 ${selectedTags.includes(tag)
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300'
                  }`}
              >
                {tag}
              </button>
            ))}
          </div>

          <div className="flex items-center justify-between pt-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {selectedTags.length} hashtags selected
            </span>
            <button
              onClick={copyToClipboard}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg
                hover:bg-blue-700 transition-colors duration-200"
            >
              <Copy className="mr-2 h-4 w-4" />
              Copy Selected
            </button>
          </div>
        </div>
      )}

      {!user && (
        <div className="mt-8 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
          <p className="text-sm text-blue-600 dark:text-blue-300 text-center">
            Sign in to unlock unlimited generations and save your favorite hashtags
          </p>
        </div>
      )}
    </div>
  );
};

export default HashtagGenerator;